import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import config from "../../data/SiteConfig";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";

import "./reset.css";
import "./index.css";
import "./fonts/font-face.css"

export default class MainLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <HelmetProvider>
        <Helmet>
          <meta name="description" content={config.siteDescription} />
        </Helmet> 
        <div className="bg" />
        <div className="bg-fill" />     
        <div className="layout-container">
          <Header />
          {children}
          <Footer />
        </div>
      </HelmetProvider>    
    );
  }
}
